// React Chatbot Component
import React, { useState, useEffect, useRef } from "react";

const Chatbot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [userMessage, setUserMessage] = useState("");

  const chatContainerRef = useRef(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSend = async () => {
    if (userMessage.trim() === "") return;

    setMessages([...messages, { sender: "user", text: userMessage }]);
    const currentQuestion = userMessage;
    setUserMessage("");

    try {
      const response = await fetch("https://suniljha.in:4001/api/chatbot", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ question: currentQuestion }),
      });

      const data = await response.json();

      if (data.error) {
        setMessages((prev) => [...prev, { sender: "bot", text: data.error }]);
      } else if (data.type === "out_of_context") {
        // Add messages sequentially with delays
        const addMessageWithDelay = (message, index) => {
          setTimeout(() => {
            setMessages((prev) => [...prev, { sender: "bot", text: message }]);
          }, index * 1000); // 1 second delay between messages
        };

        data.messages.forEach((message, index) => {
          addMessageWithDelay(message, index);
        });
      } else {
        setMessages((prev) => [...prev, { sender: "bot", text: data.messages[0] }]);
      }
    } catch (error) {
      setMessages((prev) => [
        ...prev,
        { sender: "bot", text: "Error fetching response. Please try again." },
      ]);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSend();
    }
  };

  const generateGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return "Good Morning";
    if (hour < 18) return "Good Afternoon";
    return "Good Evening";
  };

  useEffect(() => {
    if (isOpen) {
      const initialGreeting = generateGreeting();
      setMessages([{ sender: "bot", text: initialGreeting }]);

      setTimeout(() => {
        setMessages((prev) => [
          ...prev,
          { sender: "bot", text: "Welcome to Prof. Sunil Jha's Website" },
        ]);
      }, 1000);

      setTimeout(() => {
        setMessages((prev) => [
          ...prev,
          { sender: "bot", text: "How can I help you today?" },
        ]);
      }, 2000);
    }
  }, [isOpen]);

  const handleScroll = (e) => {
    const chatContainer = chatContainerRef.current;
    if (!chatContainer) return;

    const { scrollTop, scrollHeight, clientHeight } = chatContainer;
    const isScrolledToTop = scrollTop === 0;
    const isScrolledToBottom = scrollTop + clientHeight === scrollHeight;

    if ((isScrolledToTop && e.deltaY < 0) || (isScrolledToBottom && e.deltaY > 0)) {
      e.preventDefault();
    }
    e.stopPropagation();
  };

  const handleMouseEnter = () => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.style.overflowY = "auto";
      chatContainer.addEventListener("wheel", handleScroll, { passive: false });
    }
  };

  const handleMouseLeave = () => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.style.overflowY = "hidden";
      chatContainer.removeEventListener("wheel", handleScroll);
    }
  };

  useEffect(() => {
    return () => {
      const chatContainer = chatContainerRef.current;
      if (chatContainer) {
        chatContainer.removeEventListener("wheel", handleScroll);
      }
    };
  }, []);

  return (
    <div className="fixed bottom-4 right-4 z-50">
      <button
        onClick={handleToggle}
        className="bg-blue-600 text-white rounded-full p-3 shadow-lg hover:bg-blue-700 focus:outline-none"
      >
        Smart Assistant💬
      </button>

      {isOpen && (
        <div className="w-96 h-[450px] bg-white shadow-lg rounded-lg flex flex-col fixed bottom-16 right-4">
          <div className="bg-blue-600 text-white p-5 rounded-t-lg flex justify-between items-center">
            <h3>Smart Assistant</h3>
            <button onClick={handleToggle} className="text-xl font-bold">
              ×
            </button>
          </div>

          <div
            ref={chatContainerRef}
            className="chat-messages flex-1 p-5 overflow-hidden"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ isolation: "isolate" }}
          >
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`mb-3 ${
                  msg.sender === "user"
                    ? "text-right text-blue-600"
                    : "text-left text-gray-800"
                }`}
              >
                <p
                  className={`inline-block p-3 rounded-lg ${
                    msg.sender === "user" ? "bg-blue-100" : "bg-gray-100"
                  }`}
                >
                  {msg.text}
                </p>
              </div>
            ))}
          </div>

          <div className="p-5 border-t border-gray-300 flex items-center">
            <input
              type="text"
              placeholder="Type your message..."
              className="flex-1 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={userMessage}
              onChange={(e) => setUserMessage(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <button
              onClick={handleSend}
              className="ml-3 bg-blue-600 text-white px-5 py-3 rounded-lg hover:bg-blue-700 focus:outline-none"
            >
              Send
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Chatbot;
