import React, { useState } from 'react';
import './Gallery.css';
import backgroundVideo from '../assets/background.mp4';

import image1 from '../assets/image1.jpg';
import image2 from '../assets/image2.jpg';
import image3 from '../assets/image3.jpg';
import image4 from '../assets/image4.jpg';
import image5 from '../assets/image5.jpg';

const gallerySections = [
  {
    image: image1,
    title: "Implementation for vechile navigation and testing",
    description: "",
    reverse: false, // Image on the left
  },
  {
    image: image2,
    title: "Laser Micro Machining",
    description: "",
    reverse: true, // Image on the right
  },
  {
    image: image3,
    title: "Dry Micro Electrical Discharge Machine",
    description: "",
    reverse: false, // Image on the left
  },
  {
    image: image4,
    title: "Micro Grinding and Micro Milling using High Speed Spindle",
    description: "",
    reverse: true, // Image on the right
  },
  {
    image: image5,
    title: "Machine learning-based Tool Wear Prediction model for CNC Micromilling",
    description: "",
    reverse: false, // Image on the left
  },
];

const Gallery = () => {
  const [fullscreenImage, setFullscreenImage] = useState(null);

  const openFullscreen = (image) => {
    setFullscreenImage(image);
  };

  const closeFullscreen = () => {
    setFullscreenImage(null);
  };

  return (
    <div className="gallery-page">
      {/* Background video */}
      <video className="video-background" autoPlay loop muted playsInline>
        <source src={backgroundVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Page Title */}
      <div className="gallery-title">
        <h1>Gallery</h1>
      </div>

      {/* Gallery Sections */}
      {gallerySections.map((section, index) => (
        <div
          key={index}
          className={`gallery-section ${
            section.reverse ? 'reverse-layout' : ''
          }`}
        >
          <div className="gallery-image">
            <img
              src={section.image}
              alt={`Section ${index + 1}`}
              onClick={() => openFullscreen(section.image)} // OnClick to open fullscreen
            />
          </div>
          <div className="gallery-text">
            <h2>{section.title}</h2>
            <p>{section.description}</p>
          </div>
        </div>
      ))}

      {/* Fullscreen Modal */}
      {fullscreenImage && (
        <div className="fullscreen-modal" onClick={closeFullscreen}>
          <button className="close-button" aria-label="Close image">
            <span className="close-icon">×</span>
          </button>
          <img
            src={fullscreenImage}
            alt="Fullscreen view"
            className="fullscreen-image"
            onClick={(e) => e.stopPropagation()} // Prevent modal from closing on image click
          />
        </div>
      )}
    </div>
  );
};

export default Gallery;

// "use client"

// import { useState, useRef, useEffect } from 'react'
// import { motion, AnimatePresence } from 'framer-motion'
// import { gsap } from 'gsap'
// import { ScrollTrigger } from 'gsap/ScrollTrigger'
// import Image from 'next/image'
// import { TiLocationArrow } from 'react-icons/ti'

// gsap.registerPlugin(ScrollTrigger)

// const gallerySections = [
//   {
//     image: "/assets/image1.jpg",
//     title: "Implementation for vechile navigation and testing",
//     description: "",
//     reverse: false,
//   },
//   {
//     image: "/assets/image2.jpg",
//     title: "Laser Micro Machining",
//     description: "",
//     reverse: true,
//   },
//   {
//     image: "/assets/image3.jpg",
//     title: "Dry Micro Electrical Discharge Machine",
//     description: "",
//     reverse: false,
//   },
//   {
//     image: "/assets/image4.jpg",
//     title: "Micro Grinding and Micro Milling using High Speed Spindle",
//     description: "",
//     reverse: true,
//   },
//   {
//     image: "/assets/image5.jpg",
//     title: "Machine learning-based Tool Wear Prediction model for CNC Micromilling",
//     description: "",
//     reverse: false,
//   },
// ]

// const Gallery = () => {
//   const [fullscreenImage, setFullscreenImage] = useState<string | null>(null)
//   const sectionRefs = useRef<(HTMLDivElement | null)[]>([])

//   useEffect(() => {
//     sectionRefs.current.forEach((section, index) => {
//       if (section) {
//         gsap.from(section, {
//           opacity: 0,
//           y: 50,
//           duration: 1,
//           scrollTrigger: {
//             trigger: section,
//             start: 'top bottom-=100',
//             end: 'bottom center',
//             scrub: 1,
//           },
//         })
//       }
//     })
//   }, [])

//   const openFullscreen = (image: string) => {
//     setFullscreenImage(image)
//   }

//   const closeFullscreen = () => {
//     setFullscreenImage(null)
//   }

//   return (
//     <div className="min-h-screen bg-black text-white">
//       <video
//         className="fixed left-0 top-0 -z-10 h-full w-full object-cover opacity-30"
//         autoPlay
//         loop
//         muted
//         playsInline
//       >
//         <source src="/assets/background.mp4" type="video/mp4" />
//         Your browser does not support the video tag.
//       </video>

//       <div className="container mx-auto px-4 py-16">
//         <motion.h1
//           initial={{ opacity: 0, y: -50 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 0.8 }}
//           className="mb-16 text-center font-sans text-6xl font-bold tracking-tight"
//         >
//           Gallery
//         </motion.h1>

//         {gallerySections.map((section, index) => (
//           <div
//             key={index}
//             ref={(el) => (sectionRefs.current[index] = el)}
//             className={`mb-32 flex flex-col items-center gap-8 md:flex-row ${
//               section.reverse ? 'md:flex-row-reverse' : ''
//             }`}
//           >
//             <div className="w-full md:w-1/2">
//               <motion.div
//                 whileHover={{ scale: 1.05 }}
//                 whileTap={{ scale: 0.95 }}
//                 className="overflow-hidden rounded-lg shadow-2xl"
//               >
//                 <Image
//                   src={section.image}
//                   alt={`Section ${index + 1}`}
//                   width={800}
//                   height={600}
//                   objectFit="cover"
//                   className="cursor-pointer transition-transform duration-300 hover:scale-110"
//                   onClick={() => openFullscreen(section.image)}
//                 />
//               </motion.div>
//             </div>
//             <div className="w-full md:w-1/2">
//               <h2 className="mb-4 font-sans text-3xl font-semibold leading-tight">
//                 {section.title}
//               </h2>
//               {section.description && (
//                 <p className="text-lg text-gray-300">{section.description}</p>
//               )}
//               <motion.button
//                 whileHover={{ scale: 1.05 }}
//                 whileTap={{ scale: 0.95 }}
//                 className="mt-6 flex items-center gap-2 rounded-full bg-white px-6 py-3 font-semibold text-black transition-colors hover:bg-gray-200"
//               >
//                 Learn More <TiLocationArrow className="text-xl" />
//               </motion.button>
//             </div>
//           </div>
//         ))}
//       </div>

//       <AnimatePresence>
//         {fullscreenImage && (
//           <motion.div
//             initial={{ opacity: 0 }}
//             animate={{ opacity: 1 }}
//             exit={{ opacity: 0 }}
//             className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-90"
//             onClick={closeFullscreen}
//           >
//             <motion.img
//               src={fullscreenImage}
//               alt="Fullscreen view"
//               className="max-h-[90vh] max-w-[90vw] object-contain"
//               initial={{ scale: 0.8 }}
//               animate={{ scale: 1 }}
//               exit={{ scale: 0.8 }}
//             />
//             <button
//               className="absolute right-4 top-4 text-4xl text-white"
//               onClick={closeFullscreen}
//             >
//               &times;
//             </button>
//           </motion.div>
//         )}
//       </AnimatePresence>
//     </div>
//   )
// }

// export default Gallery

