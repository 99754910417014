import React from 'react';

const Contact = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 text-gray-800 py-8 px-4 ">
      <h1 className="text-4xl font-bold text-black  mb-6">Contact</h1>
      <div className="bg-white shadow-lg rounded-xl p-6 max-w-2xl w-full">
        {/* Name Section */}
        <div className="mb-6">
          <h2 className="text-2xl font-semibold text-black mb-2">Prof. Sunil Jha</h2>
          <p className="text-gray-600">Professor, Department of Mechanical Engineering, IIT Delhi</p>
        </div>
        {/* Email Section */}
        <div className="mb-6">
          <h3 className="text-lg font-medium text-black mb-1">Email</h3>
          <p className="text-gray-600">suniljha [at] iitd [dot] ac [dot] in</p>
        </div>
        {/* Address Section */}
        <div>
          <h3 className="text-lg font-medium text-black mb-1">Address</h3>
          <p className="text-gray-600">
            Department of Mechanical Engineering, <br />
            IIT Delhi, Hauz Khas, <br />
            New Delhi, India
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
